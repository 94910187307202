import * as React from 'react';
import { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { required, minLength, maxLength, minValue, maxValue, number, regex, email, choices } from 'react-admin';
import { useRedirect, List, Datagrid, TextField, Filter, TextInput, BooleanInput, EditButton, Edit, Create, SimpleForm, NumberInput, BulkDeleteWithConfirmButton, Button, SaveButton, Toolbar, TopToolbar, DeleteWithConfirmButton, Show, SimpleShowLayout, ImageField, ShowButton } from 'react-admin';
import { ArrowBack } from '@material-ui/icons';
import Typography from '@material-ui/core/Typography';

//import ResetOrderNum from './Set_ResetOrderNum';

{/* 
  import { SetList, SetCreate, SetEdit, SetShow } from './components/Set';

  <Resource name="CMSSet" list={SetList} create={SetCreate} edit={SetEdit} show={SetShow} />
  <Resource name="CMSSet" list={ListGuesser} create={EditGuesser} edit={EditGuesser} edit={ShowGuesser} /> 
*/}

const useStyles = makeStyles({
  imageField: {
    '& img': { width: 60 }
  },
});

const useTxtData = {
  page: {
    tableName: '平台参数',
  },
  table: {
    id: 'id',
    pingtaiCommissionPercentage: '平台佣金比例',
    parentMemberCommissionPercentage: '上级推广者佣金比例',
    parentMember2CommissionPercentage: '上上级推广者佣金比例',
  }
};

//分页列表页面
export const SetList = (props) => {
  //筛选器模块
  const Filters = (props) => {
    return (
      <Filter {...props}>
        <TextInput label="ID" source="id" alwaysOn resettable />
        {/* <TextInput label="XX" source="name" alwaysOn resettable /> */}
      </Filter>
    );
  };
  //批量操作模块
  const AssetBulkActionButtons = (props) => {
    return (
      <Fragment>
        {/* <ResetOrderNum label="重置排序" {...props} /> */}
        <BulkDeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除所选记录吗？" />
      </Fragment>
    );
  };
  //行右侧功能按钮
  const RowAction = (props) => {
    return (
      <div style={{ textAlign: 'right' }}>
        <EditButton {...props} /><br />
        <ShowButton {...props} /><br />
        {/* <DeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除该记录吗？" /> */}
      </div>
    );
  }

  const classes = useStyles();
  return (
    <List {...props} title={useTxtData.page.tableName + '列表'} sort={{ field: 'id', order: 'DESC' }} filters={<Filters />} bulkActionButtons={false} >
      <Datagrid>
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        <TextField label={useTxtData.table.id} source="id" />
        <TextField label={useTxtData.table.pingtaiCommissionPercentage} source="pingtaiCommissionPercentage" />
        <TextField label={useTxtData.table.parentMemberCommissionPercentage} source="parentMemberCommissionPercentage" />
        <TextField label={useTxtData.table.parentMember2CommissionPercentage} source="parentMember2CommissionPercentage" />
        <RowAction />
      </Datagrid>
    </List>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//SHOW页面
export const SetShow = (props) => {
  const ShowActions = (props) => {
    const { basePath, data } = props; const redirect = useRedirect();
    return (
      <TopToolbar>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text">
          <ArrowBack />
        </Button>
        <EditButton basePath={basePath} record={data} />
      </TopToolbar>
    );
  };

  return (
    <Show title={'查看' + useTxtData.page.tableName} {...props} actions={<ShowActions />}>
      <SimpleShowLayout>
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        <TextField label={useTxtData.table.id} source="id" />
        <TextField label={useTxtData.table.pingtaiCommissionPercentage} source="pingtaiCommissionPercentage" />
        <TextField label={useTxtData.table.parentMemberCommissionPercentage} source="parentMemberCommissionPercentage" />
        <TextField label={useTxtData.table.parentMember2CommissionPercentage} source="parentMember2CommissionPercentage" />
      </SimpleShowLayout>
    </Show>
  );
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//编辑页面
export const SetEdit = (props) => {
  const PostEditActions = (props) => {
    const { basePath } = props; const redirect = useRedirect();
    return (
      <TopToolbar {...props}>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text">
          <ArrowBack />
        </Button>
      </TopToolbar>
    );
  };
  const PostEditToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton />
      </Toolbar>
    );
  };

  const Aside = () => (
    <div style={{ width: 500, margin: '1em' }}>
      <Typography variant="h6">{useTxtData.page.tableName}说明</Typography>
      <Typography variant="body2">
        1.此处是全局设置
      </Typography>
      <Typography variant="body2">
        2.上级推广者抽取佣金 加 上上级推广者抽取佣金 不能大于 平台抽取佣金
      </Typography>
      <Typography variant="body2">
        3.平台实际佣金 = 平台抽取佣金 - 上级推广者抽取佣金 - 上上级推广者抽取佣金
      </Typography>
    </div>
  );

  return (
    <Edit title={'编辑' + useTxtData.page.tableName} undoable={false} aside={<Aside />} {...props} actions={<PostEditActions />}>
      <MyForm Edit={true} toolbar={<PostEditToolbar />} />
    </Edit>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//创建页面
export const SetCreate = (props) => {
  const PostCreateActions = (props) => {
    const { basePath } = props; const redirect = useRedirect();
    return (
      <TopToolbar {...props}>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text" >
          <ArrowBack />
        </Button>
      </TopToolbar>
    );
  };
  const PostCreateToolbar = (props) => {
    const { basePath } = props;
    return (
      <Toolbar {...props}>
        <SaveButton label="保存&列表" redirect={basePath} submitOnEnter={true} />
        <SaveButton label="保存&继续" redirect={false} submitOnEnter={false} variant="text" />
      </Toolbar>
    );
  };

  return (
    <Create title={'新增' + useTxtData.page.tableName} undoable={false} {...props} actions={<PostCreateActions />}>
      <MyForm Create={true} toolbar={<PostCreateToolbar />} />
    </Create>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//Create和Edit公用表单
const MyForm = (props) => {
  let { Edit, Create } = props;
  return (
    <SimpleForm {...props} >
      {/* <TextInput source="Name" /> */}
      {/* <NumberInput source="OrderNum" /> */}
      {/* <NumberInput label={useTxtData.table.id} source="id" step={1} validate={[required()]} /> */}
      <NumberInput fullWidth label={useTxtData.table.pingtaiCommissionPercentage} source="pingtaiCommissionPercentage" validate={[required(), minValue(0), maxValue(0.3)]}
        helperText='平台抽取佣金 例如:0.3(表示收取30%佣金)【服务商最多可控制的分账资金是（订单金额-手续费）*该比例，所以不能写满0.3】' />
      <NumberInput fullWidth label={useTxtData.table.parentMemberCommissionPercentage} source="parentMemberCommissionPercentage" validate={[required(), minValue(0), maxValue(0.3)]}
        helperText='上级推广者抽取佣金 例如:0.1(表示收取10%佣金)' />
      <NumberInput fullWidth label={useTxtData.table.parentMember2CommissionPercentage} source="parentMember2CommissionPercentage" validate={[required(), minValue(0), maxValue(0.3)]}
        helperText='上上级推广者抽取佣金 例如:0.1(表示收取10%佣金)' />
    </SimpleForm>
  );
}