import * as React from 'react';
import { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { required, minLength, maxLength, minValue, maxValue, number, regex, email, choices } from 'react-admin';
import {
  useRedirect,
  List,
  Datagrid,
  Filter,
  Edit,
  Create,

  Toolbar,
  TopToolbar,
  DeleteWithConfirmButton,
  BulkDeleteWithConfirmButton,
  Show,
  SimpleShowLayout,

  ReferenceField,
  TextField,
  ImageField,
  BooleanField,
  RichTextField,

  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  NumberInput,
  BooleanInput,
  NullableBooleanInput,
  DateTimeInput,

  Button,
  EditButton,
  ShowButton,
  SaveButton,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { UpLoadFile, FirstImageField } from './custom/UpLoadFile';
import { ArrowBack } from '@material-ui/icons';
//import ResetOrderNum from './StoreProduct_ResetOrderNum';

{/* 
  import { StoreProductList, StoreProductCreate, StoreProductEdit, StoreProductShow } from './components/StoreProduct';

  <Resource name="CMSStoreProduct" list={StoreProductList} create={StoreProductCreate} edit={StoreProductEdit} show={StoreProductShow} />
  <Resource name="CMSStoreProduct" list={ListGuesser} create={EditGuesser} edit={EditGuesser} edit={ShowGuesser} /> 
*/}

const useStyles = makeStyles({
  imageField: {
    '& img': { width: 60 }
  },
  title: {
    maxWidth: 150,
  },
  RichTextField: {
    maxWidth: 700,
    '& img': { maxWidth: 700 }
  }
});

const useTxtData = {
  page: {
    tableName: '商家活动',
  },
  table: {
    id: 'id',
    Lock: '锁定',
    StoreId: '商家',
    Title: '活动标题',
    SubTitle: '副标题',
    VideoPath: '视频',
    Images: '大图',
    Price: '价格',
    MarketPrice: '市场价',
    SalesCount: '销售量',
    StartTime: '开始时间',
    EndTime: '结束时间',
    GuiZe: '规则',
    Content: '内容',
    Reminder: '温馨提示',
  }
};

//分页列表页面
export const StoreProductList = (props) => {
  //筛选器模块
  const Filters = (props) => {
    return (
      <Filter {...props}>
        <TextInput label="ID" source="id" alwaysOn resettable />
        {/* <TextInput label="XX" source="name" alwaysOn resettable /> */}
      </Filter>
    );
  };
  //批量操作模块
  const AssetBulkActionButtons = (props) => {
    return (
      <Fragment>
        {/* <ResetOrderNum label="重置排序" {...props} /> */}
        <BulkDeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除所选记录吗？" />
      </Fragment>
    );
  };
  //行右侧功能按钮
  const RowAction = (props) => {
    return (
      <div style={{ textAlign: 'right' }}>
        <EditButton {...props} /><br />
        <ShowButton {...props} /><br />
        <DeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除该记录吗？" />
      </div>
    );
  }

  const MTime = (props) => {
    return (
      <div>
        <div>开始：{props.record.StartTime}</div>
        <div>结束：{props.record.EndTime}</div>
      </div>
    );
  }

  const YuLanAndShare = (props) => {
    return (
      <Fragment>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>总共预览：{props.record.YuLanAllTotal}</div>
          <div style={{ marginLeft: 5 }}>
            <div>本月：{props.record.YuLanThisMouthTotal}</div>
            <div>本周：{props.record.YuLanThisWeekTotal}</div>
          </div>
          <div style={{ marginLeft: 5 }}>
            <div>昨天：{props.record.YuLanYesterdayTotal}</div>
            <div>今天：{props.record.YuLanTodayTotal}</div>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: 6 }}>
          <div>总共分享：{props.record.ShareAllTotal}</div>
          <div style={{ marginLeft: 5 }}>
            <div>本月：{props.record.ShareThisMouthTotal}</div>
            <div>本周：{props.record.ShareThisWeekTotal}</div>
          </div>
          <div style={{ marginLeft: 5 }}>
            <div>昨天：{props.record.ShareYesterdayTotal}</div>
            <div>今天：{props.record.ShareTodayTotal}</div>
          </div>
        </div>
      </Fragment>
    );
  }

  const classes = useStyles();
  return (
    <List {...props} title={useTxtData.page.tableName + '列表'} sort={{ field: 'id', order: 'DESC' }} filters={<Filters />} bulkActionButtons={<AssetBulkActionButtons />} >
      <Datagrid>
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        {/* <TextField label={useTxtData.table.id} source="id" /> */}
        <BooleanField label={useTxtData.table.Lock} source="Lock" />
        <ReferenceField label={useTxtData.table.StoreId} source="StoreId" reference="Admin/CMSStore" link=''>
          <TextField source="ShortName" />
        </ReferenceField>
        <TextField label={useTxtData.table.Title} source="Title" cellClassName={classes.title} />
        {/* <TextField label={useTxtData.table.SubTitle} source="SubTitle" /> */}
        {/* <VideoView label={useTxtData.table.VideoPath} source="VideoPath" /> */}
        <FirstImageField label={useTxtData.table.Images} source="Images" className={classes.imageField} />
        <TextField label={useTxtData.table.Price} source="Price" />
        {/* <TextField label={useTxtData.table.MarketPrice} source="MarketPrice" /> */}
        <TextField label={useTxtData.table.SalesCount} source="SalesCount" />
        {/* <TextField label={useTxtData.table.StartTime} source="StartTime" /> */}
        {/* <TextField label={useTxtData.table.EndTime} source="EndTime" /> */}
        <MTime label='时间' />
        {/* <TextField label={useTxtData.table.GuiZe} source="GuiZe" />
        <TextField label={useTxtData.table.Content} source="Content" />
        <TextField label={useTxtData.table.Reminder} source="Reminder" /> */}
        <YuLanAndShare label='预览统计/分享统计' />
        <RowAction />
      </Datagrid>
    </List>
  );
};

////////////////////////////////////////////////////////////////////////
const VideoView = ({ record, source }) => {
  return <a target='blank' href={record[source]}>{record[source]}</a>
}
////////////////////////////////////////////////////////////////////////

//SHOW页面
export const StoreProductShow = (props) => {
  const ShowActions = (props) => {
    const { basePath, data } = props; const redirect = useRedirect();
    return (
      <TopToolbar>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text">
          <ArrowBack />
        </Button>
        <EditButton basePath={basePath} record={data} />
      </TopToolbar>
    );
  };

  const classes = useStyles();
  return (
    <Show title={'查看' + useTxtData.page.tableName} {...props} actions={<ShowActions />}>
      <SimpleShowLayout>
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        <TextField label={useTxtData.table.id} source="id" />
        <BooleanField label={useTxtData.table.Lock} source="Lock" />
        <ReferenceField label={useTxtData.table.StoreId} source="StoreId" reference="Admin/CMSStore" link=''>
          <TextField source="ShortName" />
        </ReferenceField>
        <TextField label={useTxtData.table.Title} source="Title" />
        <TextField label={useTxtData.table.SubTitle} source="SubTitle" />
        <VideoView label={useTxtData.table.VideoPath} source="VideoPath" />
        <FirstImageField label={useTxtData.table.Images} source="Images" className={classes.imageField} />
        <TextField label={useTxtData.table.Price} source="Price" />
        <TextField label={useTxtData.table.MarketPrice} source="MarketPrice" />
        <TextField label={useTxtData.table.SalesCount} source="SalesCount" />
        <TextField label={useTxtData.table.StartTime} source="StartTime" />
        <TextField label={useTxtData.table.EndTime} source="EndTime" />
        <TextField label={useTxtData.table.GuiZe} source="GuiZe" />
        <RichTextField label={useTxtData.table.Content} source="Content" className={classes.RichTextField} />
        <RichTextField label={useTxtData.table.Reminder} source="Reminder" className={classes.RichTextField} />
      </SimpleShowLayout>
    </Show>
  );
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//编辑页面
export const StoreProductEdit = (props) => {
  const PostEditActions = (props) => {
    const { basePath } = props; const redirect = useRedirect();
    return (
      <TopToolbar {...props}>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text">
          <ArrowBack />
        </Button>
      </TopToolbar>
    );
  };
  const PostEditToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton />
      </Toolbar>
    );
  };
  return (
    <Edit title={'编辑' + useTxtData.page.tableName} undoable={false} {...props} actions={<PostEditActions />}>
      <MyForm Edit={true} toolbar={<PostEditToolbar />} />
    </Edit>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//创建页面
export const StoreProductCreate = (props) => {
  const PostCreateActions = (props) => {
    const { basePath } = props; const redirect = useRedirect();
    return (
      <TopToolbar {...props}>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text" >
          <ArrowBack />
        </Button>
      </TopToolbar>
    );
  };
  const PostCreateToolbar = (props) => {
    const { basePath } = props;
    return (
      <Toolbar {...props}>
        <SaveButton label="保存&列表" redirect={basePath} submitOnEnter={true} />
        <SaveButton label="保存&继续" redirect={false} submitOnEnter={false} variant="text" />
      </Toolbar>
    );
  };

  return (
    <Create title={'新增' + useTxtData.page.tableName} undoable={false} {...props} actions={<PostCreateActions />}>
      <MyForm Create={true} toolbar={<PostCreateToolbar />} />
    </Create>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//Create和Edit公用表单
const MyForm = (props) => {
  let { Edit, Create } = props;
  return (
    <SimpleForm {...props} >
      {/* <TextInput source="Name" /> */}
      {/* <NumberInput source="OrderNum" /> */}
      {/* <NumberInput label={useTxtData.table.id} source="id" step={1} validate={[required()]} /> */}
      <BooleanInput label={useTxtData.table.Lock} source="Lock" validate={[]} />
      <ReferenceInput label={useTxtData.table.StoreId} source="StoreId" reference="Admin/CMSStore" perPage={9999} validate={required()}>
        <SelectInput optionText="ShortName" />
      </ReferenceInput>
      <TextInput label={useTxtData.table.Title} source="Title" style={{ width: '100%' }} validate={[required()]} />
      <TextInput label={useTxtData.table.SubTitle} source="SubTitle" style={{ width: '100%' }} validate={[required()]} />
      {/* <TextInput label={useTxtData.table.VideoPath} source="VideoPath" validate={[required()]} /> */}
      <UpLoadFile notImg={true} label={useTxtData.table.VideoPath} source="VideoPath" accept="video/mp4" validate={[]} helperText='提示 建议不要超过20M' />

      {/* <TextInput label={useTxtData.table.Images} source="Images" validate={[required()]} /> */}
      <UpLoadFile multiple={true} label={useTxtData.table.Images} source="Images" accept="image/*" validate={[required()]} helperText='建议尺寸414px*200px或828px*400px' />

      <NumberInput label={useTxtData.table.Price} source="Price" validate={[required()]} />
      <NumberInput label={useTxtData.table.MarketPrice} source="MarketPrice" validate={[required()]} />
      <NumberInput label={useTxtData.table.SalesCount} source="SalesCount" step={1} validate={[required()]} />
      <DateTimeInput label={useTxtData.table.StartTime} source="StartTime" validate={[required()]} />
      <DateTimeInput label={useTxtData.table.EndTime} source="EndTime" validate={[required()]} />
      <TextInput label={useTxtData.table.GuiZe} style={{ width: '100%' }} source="GuiZe" validate={[required()]} />
      <RichTextInput label={useTxtData.table.Content} source="Content" validate={[required()]} />
      <RichTextInput label={useTxtData.table.Reminder} source="Reminder" validate={[required()]} />
    </SimpleForm>
  );
}