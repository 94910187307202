import * as React from 'react';
import { Fragment } from 'react';
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { required, minLength, maxLength, minValue, maxValue, number, regex, email, choices } from 'react-admin';
import { useRedirect, List, Datagrid, TextField, Filter, TextInput, BooleanInput, EditButton, Edit, Create, SimpleForm, NumberInput, BulkDeleteWithConfirmButton, Button, SaveButton, Toolbar, TopToolbar, DeleteWithConfirmButton, Show, SimpleShowLayout, ImageField, ShowButton, ReferenceField, TabbedShowLayout, Tab, ReferenceManyField, DateField, useDataProvider } from 'react-admin';
import { ArrowBack } from '@material-ui/icons';
//import ResetOrderNum from './MemberOrder_ResetOrderNum';

{/* 
  import { MemberOrderList, MemberOrderCreate, MemberOrderEdit, MemberOrderShow } from './components/MemberOrder';

  <Resource name="CMSMemberOrder" list={MemberOrderList} create={MemberOrderCreate} edit={MemberOrderEdit} show={MemberOrderShow} />
  <Resource name="CMSMemberOrder" list={ListGuesser} create={EditGuesser} edit={EditGuesser} edit={ShowGuesser} /> 
*/}

const useStyles = makeStyles({
  imageField: {
    '& img': { width: 60 }
  },
  list2: {
    '& .MuiFormControl-root': { width: '100%' }
  }
});

const useTxtData = {
  page: {
    tableName: '交易记录',
  },
  table: {
    id: 'id',
    storeId: '商家',
    memberId: '会员',
    memberOpenId: '会员 OpenId',
    parentMemberId: '上级推广者',
    parentMemberOpenId: '上级推广者 OpenId',
    parentMember2Id: '上上级推广者',
    parentMember2OpenId: '上上级推广者 OpenId',

    orderNo: '订单号',
    transactionId: '微信交易号',

    total: '总金额(￥)',
    payerTotal: '支付金额(￥)',

    paySuccessTime: '支付时间',
    orderCreateTime: '订单生成时间',
  }
};

//分页列表页面
export const MemberOrderList = (props) => {
  //筛选器模块
  const Filters = (props) => {
    return (
      <Filter {...props}>
        <TextInput label="ID" source="id" alwaysOn resettable />
        {/* <TextInput label="XX" source="name" alwaysOn resettable /> */}
      </Filter>
    );
  };
  //批量操作模块
  const AssetBulkActionButtons = (props) => {
    return (
      <Fragment>
        {/* <ResetOrderNum label="重置排序" {...props} /> */}
        <BulkDeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除所选记录吗？" />
      </Fragment>
    );
  };
  //行右侧功能按钮
  const RowAction = (props) => {
    return (
      <div style={{ textAlign: 'right' }}>
        {/* <EditButton {...props} /><br /> */}
        {/* <ShowButton {...props} /><br /> */}
        {/* <DeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除该记录吗？" /> */}
      </div>
    );
  }

  const StoreAndMember = (props) => {
    //console.log('propsprops', props)
    return (
      <div>
        {/* <TextField {...props} label={useTxtData.table.storeId} source="storeId" /> */}
        <ReferenceField {...props} source="storeId" reference="Admin/CMSStore" link={false}>
          <TextField source="ShortName" />
        </ReferenceField><br />
        {/* <TextField {...props} label={useTxtData.table.memberId} source="memberId" /> */}
        <ReferenceField {...props} source="memberId" reference="Admin/CMSMember" link={false}>
          <TextField source="nickname" />
        </ReferenceField>
      </div>
    );
  }
  const TuiGuangZhe = (props) => {
    return (
      <div>
        {/* <TextField label={useTxtData.table.parentMemberId} source="parentMemberId" /> */}
        <ReferenceField {...props} source="parentMemberId" reference="Admin/CMSMember" link={false}>
          <TextField source="nickname" />
        </ReferenceField><br />
        {/* <TextField label={useTxtData.table.parentMember2Id} source="parentMember2Id" /> */}
        <ReferenceField {...props} source="parentMember2Id" reference="Admin/CMSMember" link={false}>
          <TextField source="nickname" />
        </ReferenceField><br />
      </div>
    );
  }
  const FormatTime = (props) => {
    let { record, source } = props;
    let dateAndTime = record[source].toString().split(' ');
    return (<div>
      <div>{dateAndTime[0]}</div>
      <div>{dateAndTime[1]}</div>
    </div>)
  }

  const OrderView = (props) => {
    let { record } = props;
    return (
      <div>
        <div>{record['orderNo']}</div>
        <div>{record['transactionId']}</div>
      </div>
    );
  }

  const classes = useStyles();
  return (
    <List {...props} title={useTxtData.page.tableName + '列表'} sort={{ field: 'paySuccessTime', order: 'DESC' }} filters={<Filters />} bulkActionButtons={false} >
      <Datagrid rowClick='show'>
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        <FormatTime label={useTxtData.table.paySuccessTime} source="paySuccessTime" />
        {/* <TextField label={useTxtData.table.id} source="id" /> */}
        {/* <TextField label={useTxtData.table.storeId} source="storeId" />
        <TextField label={useTxtData.table.memberId} source="memberId" /> */}
        <StoreAndMember label='商家/会员' />
        {/* <TextField label={useTxtData.table.memberOpenId} source="memberOpenId" /> */}
        {/* <TextField label={useTxtData.table.parentMemberId} source="parentMemberId" /> */}
        {/* <TextField label={useTxtData.table.parentMemberOpenId} source="parentMemberOpenId" /> */}
        {/* <TextField label={useTxtData.table.parentMember2Id} source="parentMember2Id" /> */}
        {/* <TextField label={useTxtData.table.parentMember2OpenId} source="parentMember2OpenId" /> */}
        <TuiGuangZhe label='上级推广者/上上级推广者' />
        {/* <TextField label={useTxtData.table.orderNo} source="orderNo" />
        <TextField label={useTxtData.table.transactionId} source="transactionId" /> */}
        <OrderView label='订单号/微信交易号' />
        <TextField label={useTxtData.table.total} source="total" />
        <TextField label={useTxtData.table.payerTotal} source="payerTotal" />
        {/* <TextField label={useTxtData.table.orderCreateTime} source="orderCreateTime" /> */}
        {/* <RowAction /> */}
      </Datagrid>
    </List>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//SHOW页面
export const MemberOrderShow = (props) => {
  const classes = useStyles();

  const ShowActions = (props) => {
    const { basePath, data } = props; const redirect = useRedirect();
    return (
      <TopToolbar>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text">
          <ArrowBack />
        </Button>
        {/* <EditButton basePath={basePath} record={data} /> */}
      </TopToolbar>
    );
  };

  const ReceiverType = (props) => {
    let txt = props.record[props.source];
    if (txt === '')
      return <div></div>
    return <div>{txt === 'MERCHANT_ID' ? '商户' : '个人'}</div>
  }

  const CommissionType = (props) => {
    let txt = props.record[props.source];
    if (txt === 'pingtai')
      return <div>平台</div>
    if (txt === 'parentMember')
      return <div>推广者</div>
    if (txt === 'finish')
      return <div>完结</div>
    if (txt === '')
      return <div></div>
  }

  const ReceiverAccount = (props) => {
    let ReceiverType = props.record['ReceiverType'];
    let ReceiverAccount = props.record[props.source];

    const dataProvider = useDataProvider();
    const [user, setUser] = useState();
    useEffect(() => {
      if (ReceiverType === 'PERSONAL_OPENID') {
        dataProvider.getList('Admin/CMSMember', {
          filter: { nicknameOrOpenid: ReceiverAccount }, pagination: { page: 1, perPage: 10 }, sort: { field: 'id', order: 'ASC' }
        })
          .then(({ data }) => {
            setUser(data.length > 0 ? data[0] : null);
          })
          .catch(error => {
          })
      }
    }, []);

    if (ReceiverAccount === '')
      return <div></div>
    if (ReceiverType === 'PERSONAL_OPENID') {
      if (!user) return <div></div>;
      return <div>{user.nickname}</div>
    }
    else
      return <div>{ReceiverAccount}</div>
  }

  const CommissionPercentage = (props) => {
    let val = parseFloat(props.record[props.source]);
    if (isNaN(val)) val = 0;
    return <div>{(val * 100).toFixed(0)}%</div>
  }

  return (
    <Show title={'查看' + useTxtData.page.tableName} {...props} actions={<ShowActions />}>
      <TabbedShowLayout>
        <Tab label="交易记录明细">
          {/* <TextField source="id" /> */}
          {/* <TextField label="名称" source="Name" /> */}
          {/* <TextField label="排序" source="OrderNum" />  */}
          <TextField label={useTxtData.table.id} source="id" />
          {/* <TextField label={useTxtData.table.storeId} source="storeId" /> */}
          <ReferenceField label={useTxtData.table.storeId} source="storeId" reference="Admin/CMSStore" link={false}>
            <TextField source="ShortName" />
          </ReferenceField>
          {/* <TextField label={useTxtData.table.memberId} source="memberId" /> */}
          <ReferenceField label={useTxtData.table.memberId} source="memberId" reference="Admin/CMSMember" link={false}>
            <TextField source="nickname" />
          </ReferenceField>
          <TextField label={useTxtData.table.memberOpenId} source="memberOpenId" />
          {/* <TextField label={useTxtData.table.parentMemberId} source="parentMemberId" /> */}
          <ReferenceField label={useTxtData.table.parentMemberId} source="parentMemberId" reference="Admin/CMSMember" link={false}>
            <TextField source="nickname" />
          </ReferenceField>
          <TextField label={useTxtData.table.parentMemberOpenId} source="parentMemberOpenId" />
          {/* <TextField label={useTxtData.table.parentMember2Id} source="parentMember2Id" /> */}
          <ReferenceField label={useTxtData.table.parentMember2Id} source="parentMember2Id" reference="Admin/CMSMember" link={false}>
            <TextField source="nickname" />
          </ReferenceField>
          <TextField label={useTxtData.table.parentMember2OpenId} source="parentMember2OpenId" />
          <TextField label={useTxtData.table.orderNo} source="orderNo" />
          <TextField label={useTxtData.table.transactionId} source="transactionId" />
          <TextField label={useTxtData.table.total} source="total" />
          <TextField label={useTxtData.table.payerTotal} source="payerTotal" />
          <TextField label={useTxtData.table.paySuccessTime} source="paySuccessTime" />
          <TextField label={useTxtData.table.orderCreateTime} source="orderCreateTime" />
        </Tab>
        <Tab label="佣金明细">
          <ReferenceManyField
            className={classes.list2}
            source="orderNo"
            reference="Admin/CMSMemberOrderCommission"
            target="orderNo"
            label="佣金明细"
            perPage={99999}
            sort={{ field: 'id', order: 'ASC' }}
          >
            <Datagrid>
              <TextField label={'返佣流水号'} source="CommissionNo" sortable={false} />
              <CommissionType label={'佣金类型'} source="CommissionType" sortable={false} />
              <ReceiverType label={'接收者类型'} source="ReceiverType" sortable={false} />
              <ReceiverAccount label={'商户号或个人'} source="ReceiverAccount" sortable={false} />
              <CommissionPercentage label={'佣金比例'} source="CommissionPercentage" sortable={false} />
              <TextField label={'佣金金额(￥)'} source="CommissionPrice" sortable={false} />
              <TextField label={'备注'} source="CommissionDescription" sortable={false} />
              <TextField label={'返佣状态'} source="Status" sortable={false} />
              <TextField label={'更新时间'} source="UpdateTime" sortable={false} />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//编辑页面
export const MemberOrderEdit = (props) => {
  const PostEditActions = (props) => {
    const { basePath } = props; const redirect = useRedirect();
    return (
      <TopToolbar {...props}>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text">
          <ArrowBack />
        </Button>
      </TopToolbar>
    );
  };
  const PostEditToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton />
      </Toolbar>
    );
  };
  return (
    <Edit title={'编辑' + useTxtData.page.tableName} undoable={false} {...props} actions={<PostEditActions />}>
      <MyForm Edit={true} toolbar={<PostEditToolbar />} />
    </Edit>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//创建页面
export const MemberOrderCreate = (props) => {
  const PostCreateActions = (props) => {
    const { basePath } = props; const redirect = useRedirect();
    return (
      <TopToolbar {...props}>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text" >
          <ArrowBack />
        </Button>
      </TopToolbar>
    );
  };
  const PostCreateToolbar = (props) => {
    const { basePath } = props;
    return (
      <Toolbar {...props}>
        <SaveButton label="保存&列表" redirect={basePath} submitOnEnter={true} />
        <SaveButton label="保存&继续" redirect={false} submitOnEnter={false} variant="text" />
      </Toolbar>
    );
  };

  return (
    <Create title={'新增' + useTxtData.page.tableName} undoable={false} {...props} actions={<PostCreateActions />}>
      <MyForm Create={true} toolbar={<PostCreateToolbar />} />
    </Create>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//Create和Edit公用表单
const MyForm = (props) => {
  let { Edit, Create } = props;
  return (
    <SimpleForm {...props} >
      {/* <TextInput source="Name" /> */}
      {/* <NumberInput source="OrderNum" /> */}
      <NumberInput label={useTxtData.table.id} source="id" step={1} validate={[required()]} />
      <NumberInput label={useTxtData.table.storeId} source="storeId" step={1} validate={[required()]} />
      <NumberInput label={useTxtData.table.memberId} source="memberId" step={1} validate={[required()]} />
      <TextInput label={useTxtData.table.memberOpenId} source="memberOpenId" validate={[required()]} />
      <NumberInput label={useTxtData.table.parentMemberId} source="parentMemberId" step={1} validate={[required()]} />
      <TextInput label={useTxtData.table.parentMemberOpenId} source="parentMemberOpenId" validate={[required()]} />
      <TextInput label={useTxtData.table.orderNo} source="orderNo" validate={[required()]} />
      <TextInput label={useTxtData.table.transactionId} source="transactionId" validate={[required()]} />
      <NumberInput label={useTxtData.table.total} source="total" validate={[required()]} />
      <NumberInput label={useTxtData.table.payerTotal} source="payerTotal" validate={[required()]} />
      <TextInput label={useTxtData.table.paySuccessTime} source="paySuccessTime" validate={[required()]} />
      <TextInput label={useTxtData.table.orderCreateTime} source="orderCreateTime" validate={[required()]} />
      <NumberInput label={useTxtData.table.parentMember2Id} source="parentMember2Id" step={1} validate={[required()]} />
      <TextInput label={useTxtData.table.parentMember2OpenId} source="parentMember2OpenId" validate={[required()]} />
    </SimpleForm>
  );
}