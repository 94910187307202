import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery, MenuItem, ListItemIcon, Collapse, useTheme, Typography } from '@material-ui/core';
import { MenuItemLink, getResources, DashboardMenuItem } from 'react-admin';
import { ExpandMore } from '@material-ui/icons';

import SettingsIcon from '@material-ui/icons/Settings';
import ImageIcon from '@material-ui/icons/Image';
import MessageIcon from '@material-ui/icons/Message';
import PersonIcon from '@material-ui/icons/Person';
import StoreIcon from '@material-ui/icons/Store';
import ReceiptIcon from '@material-ui/icons/Receipt';


const SubMenu = (props) => {
    const theme = useTheme();
    const [show, setShow] = useState(false);

    return <React.Fragment>
        <MenuItem dense={false} button onClick={() => { setShow(!show) }}>
            <ListItemIcon style={{ minWidth: 40 }}>
                {show ? <ExpandMore /> : props.leftIcon}
            </ListItemIcon>
            <Typography color="textSecondary">
                {props.title}
            </Typography>
        </MenuItem>
        <Collapse in={show} timeout="auto" unmountOnExit style={{ paddingLeft: props.open ? theme.spacing(4) : 0 }}>
            {props.children}
        </Collapse>
    </React.Fragment>
}

const Menu = ({ onMenuClick, logout }) => {
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);
    const props = { onMenuClick, logout, open };
    //console.log('resources', resources)

    const groups = [
        { name: '设置', icon: <SettingsIcon /> },
        { name: '会员', icon: <PersonIcon /> },
        { name: '商家', icon: <StoreIcon /> },
        { name: '交易', icon: <ReceiptIcon /> },
    ]

    return (
        <div>
            <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />
            {groups.map((group, index) => (
                <SubMenu key={index} {...props} title={group.name} leftIcon={group.icon}>
                    {resources.filter(t => t.options.group == group.name).map(resource => (
                        <MenuItemLink
                            key={resource.name}
                            to={`/${resource.name}`}
                            primaryText={resource.options && resource.options.label || resource.name}
                            leftIcon={resource.icon}
                            onClick={onMenuClick}
                            sidebarIsOpen={open}
                        />
                    ))}
                </SubMenu>
            ))}

            {/* <MenuItemLink
                to="/custom-route"
                primaryText="Miscellaneous"
                leftIcon={<LabelIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            /> */}
            {isXSmall && logout}
        </div>
    );
};

export default Menu;