import * as React from 'react';
import { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { required, minLength, maxLength, minValue, maxValue, number, regex, email, choices } from 'react-admin';
import { Button as CButton, Box } from '@material-ui/core';
import { useRedirect, List, Datagrid, TextField, Filter, TextInput, BooleanInput, EditButton, Edit, Create, SimpleForm, NumberInput, BulkDeleteWithConfirmButton, SaveButton, Button, Toolbar, TopToolbar, DeleteWithConfirmButton, Show, SimpleShowLayout, ImageField, ShowButton, useListContext } from 'react-admin';
import { ArrowBack, Search } from '@material-ui/icons';
import { Form } from 'react-final-form';
import ContentFilter from "@material-ui/icons/FilterList";
//import ResetOrderNum from './Member_ResetOrderNum';

{/* 
  import { MemberList, MemberCreate, MemberEdit } from './components/Member';

  <Resource name="CMSMember" list={MemberList} create={MemberCreate} edit={MemberEdit} show={MemberShow} />
  <Resource name="CMSMember" list={ListGuesser} create={EditGuesser} edit={EditGuesser} edit={ShowGuesser} /> 
*/}

const useStyles = makeStyles({
  imageField: {
    '& img': { width: 60 }
  },
});

//分页列表页面
export const MemberList = (props) => {

  //筛选器模块（自定义筛选模块，可以仿制一个搜索按钮）
  const Filters = (props) => {
    const { displayedFilters, filterValues, setFilters, hideFilter, } = useListContext();
    if (props.context === "button") return null;
    const onSubmit = values => { if (Object.keys(values).length > 0) { setFilters(values); } else { setFilters({}, []); } };
    const resetFilter = () => { setFilters({}, []); };
    return (
      <div>
        <Form onSubmit={onSubmit} initialValues={filterValues}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Box display='flex' alignItems='center'>
                <Box component="span" mr={2}>
                  <TextInput label="ID" source="id" resettable />
                </Box>
                <Box component="span" mr={2}>
                  <TextInput style={{ width: 320 }} label="openid/昵称" source="nicknameOrOpenid" alwaysOn resettable />
                </Box>
                <Box component="span" mr={2} mb={2.5}>
                  <CButton variant="contained" color="primary" type="submit" startIcon={<Search />}>搜索</CButton>
                </Box>
                <Box component="span" mr={2} mb={2.5}>
                  <CButton variant="outlined" onClick={resetFilter}>重置</CButton>
                </Box>
              </Box>
            </form>
          )}
        </Form>
      </div>
    );
  };

  //批量操作模块
  const AssetBulkActionButtons = (props) => {
    return (
      <Fragment>
        {/* <ResetOrderNum label="重置排序" {...props} /> */}
        <BulkDeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除所选记录吗？" />
      </Fragment>
    );
  };
  //行右侧功能按钮
  const RowAction = (props) => {
    return (
      <div style={{ textAlign: 'right' }}>
        {/* <EditButton {...props} /><br /> */}
        <ShowButton {...props} /><br />
        {/* <DeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除该记录吗？" /> */}
      </div>
    );
  }

  const TextFieldMultiline = ({ record, source, source2 }) => {
    return (
      <Fragment>
        <div>{record[source]}</div>
        <div>{record[source2]}</div>
      </Fragment>
    )
  }


  const classes = useStyles();
  return (
    <List {...props} title="会员列表" sort={{ field: 'id', order: 'DESC' }} filters={<Filters />} bulkActionButtons={false} >
      <Datagrid>
        <TextField source="id" />
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        {/* <TextField label="id" source="id" /> */}
        <TextField label="from" source="from" />
        {/*  <TextField label="openid" source="openid" /> */}
        {/* <TextField label="nickname" source="nickname" /> */}
        <TextFieldMultiline label="openid/昵称" source="openid" source2="nickname" />
        <TextFieldMultiline label="上级推广者/上上级推广者" source="parentNickName" source2="parentNickName2" />
        <TextField label="sex" source="sex" />
        <TextField label="province" source="province" />
        <TextField label="city" source="city" />
        <TextField label="country" source="country" />
        <ImageField className={classes.imageField} label="headimgurl" source="headimgurl" />
        {/* <TextField label="unionid" source="unionid" />
        <TextField label="loginToken" source="loginToken" /> */}
        <RowAction />
      </Datagrid>
    </List>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//SHOW页面
export const MemberShow = (props) => {
  const ShowActions = (props) => {
    const { basePath, data } = props; const redirect = useRedirect();
    return (
      <TopToolbar>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text">
          <ArrowBack />
        </Button>
        {/* <EditButton basePath={basePath} record={data} /> */}
      </TopToolbar>
    );
  };

  return (
    <Show {...props} actions={<ShowActions />}>
      <SimpleShowLayout>
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        <TextField label="id" source="id" />
        <TextField label="from" source="from" />
        <TextField label="openid" source="openid" />
        <TextField label="nickname" source="nickname" />
        <TextField label="sex" source="sex" />
        <TextField label="province" source="province" />
        <TextField label="city" source="city" />
        <TextField label="country" source="country" />
        <TextField label="headimgurl" source="headimgurl" />
        <TextField label="unionid" source="unionid" />
        <TextField label="loginToken" source="loginToken" />
      </SimpleShowLayout>
    </Show>
  );
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//编辑页面
export const MemberEdit = (props) => {
  const PostEditActions = (props) => {
    const { basePath } = props; const redirect = useRedirect();
    return (
      <TopToolbar {...props}>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text">
          <ArrowBack />
        </Button>
      </TopToolbar>
    );
  };
  const PostEditToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton />
      </Toolbar>
    );
  };
  return (
    <Edit undoable={false} {...props} actions={<PostEditActions />}>
      <MyForm Edit={true} toolbar={<PostEditToolbar />} />
    </Edit>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//创建页面
export const MemberCreate = (props) => {
  const PostCreateActions = (props) => {
    const { basePath } = props; const redirect = useRedirect();
    return (
      <TopToolbar {...props}>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text" >
          <ArrowBack />
        </Button>
      </TopToolbar>
    );
  };
  const PostCreateToolbar = (props) => {
    const { basePath } = props;
    return (
      <Toolbar {...props}>
        <SaveButton label="保存&列表" redirect={basePath} submitOnEnter={true} />
        <SaveButton label="保存&继续" redirect={false} submitOnEnter={false} variant="text" />
      </Toolbar>
    );
  };

  return (
    <Create undoable={false} {...props} actions={<PostCreateActions />}>
      <MyForm Create={true} toolbar={<PostCreateToolbar />} />
    </Create>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//Create和Edit公用表单
const MyForm = (props) => {
  let { Edit, Create } = props;
  return (
    <SimpleForm {...props} >
      {/* <TextInput source="Name" /> */}
      {/* <NumberInput source="OrderNum" /> */}
      <NumberInput source="id" step={1} validate={[required()]} />
      <TextInput source="from" validate={[required()]} />
      <TextInput source="openid" validate={[required()]} />
      <TextInput source="nickname" validate={[required()]} />
      <NumberInput source="sex" step={1} validate={[required()]} />
      <TextInput source="province" validate={[required()]} />
      <TextInput source="city" validate={[required()]} />
      <TextInput source="country" validate={[required()]} />
      <TextInput source="headimgurl" validate={[required()]} />
      <TextInput source="unionid" validate={[required()]} />
      <TextInput source="loginToken" validate={[required()]} />
    </SimpleForm>
  );
}