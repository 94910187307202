import * as React from 'react';
import { Fragment } from 'react';
import {
  useRedirect,
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  EditButton,
  Edit,
  Create,
  SimpleForm,
  NumberInput,
  BulkDeleteWithConfirmButton,
  Button,
  SaveButton,
  Toolbar,
  TopToolbar,
  DeleteWithConfirmButton
} from 'react-admin';
import {
  required,
  minLength,
  maxLength,
  minValue,
  maxValue,
  number,
  regex,
  email,
  choices
} from 'react-admin';
import { ArrowBack } from '@material-ui/icons';


{/* 
  import { AdminList, AdminCreate, AdminEdit } from './components/Admin';
  <Resource name="CMSAdmin" list={AdminList} create={AdminCreate} edit={AdminEdit} />
  <Resource name="CMSAdmin" list={ListGuesser} create={EditGuesser} edit={EditGuesser} /> 
*/}

//分页列表页面
export const AdminList = (props) => {
  //筛选器模块
  const Filters = (props) => {
    return (
      <Filter {...props}>
        <TextInput label="ID" source="id" alwaysOn resettable />
        {/* <TextInput label="XX" source="name" alwaysOn resettable /> */}
      </Filter>
    );
  };
  //批量操作模块
  const AssetBulkActionButtons = (props) => {
    return (
      <Fragment>

        <BulkDeleteWithConfirmButton
          {...props}
          confirmTitle="删除确认"
          confirmContent="确认要删除所选记录吗？"
        />
      </Fragment>
    );
  };
  //行右侧功能按钮
  const RowAction = (props) => {
    return (
      <div style={{ textAlign: 'right' }}>
        <EditButton {...props} />
        {/* <DeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除该记录吗？" /> */}
      </div>
    );
  }

  return (
    <List
      {...props}
      title="管理员"
      sort={{ field: 'id', order: 'Asc' }}
      filters={<Filters />}
      bulkActionButtons={false}
    >
      <Datagrid  >
        <TextField source="id" />
        <TextField label="账号" source="Account" />
        <TextField label="昵称" source="NickName" />
        <RowAction />
      </Datagrid>
    </List>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//编辑页面
export const AdminEdit = (props) => {
  const PostEditActions = (props) => {
    const { basePath } = props;
    const redirect = useRedirect();
    return (
      <TopToolbar {...props}>
        <Button
          label="返回列表"
          onClick={() => redirect(basePath)}
          variant="text"
        >
          <ArrowBack />
        </Button>
      </TopToolbar>
    );
  };
  const PostEditToolbar = (props) => {
    const { basePath } = props;
    return (
      <Toolbar {...props}>
        <SaveButton />
      </Toolbar>
    );
  };
  return (
    <Edit title='编辑管理员' undoable={false} {...props} actions={<PostEditActions />}>
      <SimpleForm toolbar={<PostEditToolbar />}>
        {/* <TextInput source="Account" label='账号' validate={required()} /> */}
        <TextField source="Account" label='账号' />
        <TextInput source="NickName" label='昵称' validate={required()} />
      </SimpleForm>
    </Edit>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//创建页面
export const AdminCreate = (props) => {
  const PostCreateActions = (props) => {
    const { basePath } = props;
    const redirect = useRedirect();
    return (
      <TopToolbar {...props}>
        <Button
          label="返回列表"
          onClick={() => redirect(basePath)}
          variant="text"
        >
          <ArrowBack />
        </Button>
      </TopToolbar>
    );
  };
  const PostCreateToolbar = (props) => {
    const { basePath } = props;
    return (
      <Toolbar {...props}>
        <SaveButton
          label="保存&列表"
          redirect={basePath}
          submitOnEnter={true}
        />
        <SaveButton
          label="保存&继续"
          redirect={false}
          submitOnEnter={false}
          variant="text"
        />
      </Toolbar>
    );
  };

  return (
    <Create undoable={false} {...props} actions={<PostCreateActions />}>
      <SimpleForm toolbar={<PostCreateToolbar />}>
        {/* <TextInput source="id" /> */}
        <TextInput source="Name" />
        <TextInput source="OrderNum" />
      </SimpleForm>
    </Create>
  );
};
