import * as React from 'react';
import { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { required, minLength, maxLength, minValue, maxValue, number, regex, email, choices } from 'react-admin';
import { useRedirect, List, Datagrid, TextField, Filter, TextInput, BooleanInput, EditButton, Edit, Create, SimpleForm, NumberInput, BulkDeleteWithConfirmButton, Button, SaveButton, Toolbar, TopToolbar, DeleteWithConfirmButton, Show, SimpleShowLayout, ImageField, ShowButton, BooleanField } from 'react-admin';
import { ArrowBack } from '@material-ui/icons';
import { UpLoadFile } from './custom/UpLoadFile';
//import ResetOrderNum from './Store_ResetOrderNum';

{/* 
  import { StoreList, StoreCreate, StoreEdit, StoreShow } from './components/Store';

  <Resource name="CMSStore" list={StoreList} create={StoreCreate} edit={StoreEdit} show={StoreShow} />
  <Resource name="CMSStore" list={ListGuesser} create={EditGuesser} edit={EditGuesser} edit={ShowGuesser} /> 
*/}

const useStyles = makeStyles({
  imageField: {
    '& img': { width: 60 }
  },
});

const useTxtData = {
  page: {
    tableName: '商家',
  },
  table: {
    id: 'id',
    Lock: '锁定',
    StoreID: '店铺ID',
    ShortName: '店铺简称',
    FullName: '店铺全称',
    Logo: 'Logo',
    Contacts: '联系人',
    ContactsMobile: '手机',
    Province: '省',
    City: '市',
    Area: '区',
    Address: '详细地址',
    Coordinate: '坐标',
  }
};

//分页列表页面
export const StoreList = (props) => {
  //筛选器模块
  const Filters = (props) => {
    return (
      <Filter {...props}>
        <TextInput label="ID" source="id" alwaysOn resettable />
        {/* <TextInput label="XX" source="name" alwaysOn resettable /> */}
      </Filter>
    );
  };
  //批量操作模块
  const AssetBulkActionButtons = (props) => {
    return (
      <Fragment>
        {/* <ResetOrderNum label="重置排序" {...props} /> */}
        <BulkDeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除所选记录吗？" />
      </Fragment>
    );
  };
  //行右侧功能按钮
  const RowAction = (props) => {
    return (
      <div style={{ textAlign: 'right' }}>
        <EditButton {...props} /><br />
        <ShowButton {...props} /><br />
        {/* <DeleteWithConfirmButton {...props} confirmTitle="删除确认" confirmContent="确认要删除该记录吗？" /> */}
      </div>
    );
  }

  const classes = useStyles();
  return (
    <List {...props} title={useTxtData.page.tableName + '列表'} sort={{ field: 'id', order: 'DESC' }} filters={<Filters />} bulkActionButtons={<AssetBulkActionButtons />} >
      <Datagrid>
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        <TextField label={useTxtData.table.id} source="id" />
        <BooleanField label={useTxtData.table.Lock} source="Lock" />
        <TextField label={useTxtData.table.StoreID} source="StoreID" />
        <TextField label={useTxtData.table.ShortName} source="ShortName" />
        <TextField label={useTxtData.table.FullName} source="FullName" />
        <ImageField label={useTxtData.table.Logo} source="Logo" className={classes.imageField} />
        <TextField label={useTxtData.table.Contacts} source="Contacts" />
        <TextField label={useTxtData.table.ContactsMobile} source="ContactsMobile" />
        <TextField label={useTxtData.table.Province} source="Province" />
        <TextField label={useTxtData.table.City} source="City" />
        <TextField label={useTxtData.table.Area} source="Area" />
        <TextField label={useTxtData.table.Address} source="Address" />
        <TextField label={useTxtData.table.Coordinate} source="Coordinate" />
        <RowAction />
      </Datagrid>
    </List>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//SHOW页面
export const StoreShow = (props) => {
  const ShowActions = (props) => {
    const { basePath, data } = props; const redirect = useRedirect();
    return (
      <TopToolbar>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text">
          <ArrowBack />
        </Button>
        <EditButton basePath={basePath} record={data} />
      </TopToolbar>
    );
  };

  const classes = useStyles();
  return (
    <Show title={'查看' + useTxtData.page.tableName} {...props} actions={<ShowActions />}>
      <SimpleShowLayout>
        {/* <TextField source="id" /> */}
        {/* <TextField label="名称" source="Name" /> */}
        {/* <TextField label="排序" source="OrderNum" />  */}
        <TextField label={useTxtData.table.id} source="id" />
        <BooleanField label={useTxtData.table.Lock} source="Lock" />
        <TextField label={useTxtData.table.StoreID} source="StoreID" />
        <TextField label={useTxtData.table.ShortName} source="ShortName" />
        <TextField label={useTxtData.table.FullName} source="FullName" />
        <ImageField label={useTxtData.table.Logo} source="Logo" className={classes.imageField} />
        <TextField label={useTxtData.table.Contacts} source="Contacts" />
        <TextField label={useTxtData.table.ContactsMobile} source="ContactsMobile" />
        <TextField label={useTxtData.table.Province} source="Province" />
        <TextField label={useTxtData.table.City} source="City" />
        <TextField label={useTxtData.table.Area} source="Area" />
        <TextField label={useTxtData.table.Address} source="Address" />
        <TextField label={useTxtData.table.Coordinate} source="Coordinate" />
      </SimpleShowLayout>
    </Show>
  );
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//编辑页面
export const StoreEdit = (props) => {
  const PostEditActions = (props) => {
    const { basePath } = props; const redirect = useRedirect();
    return (
      <TopToolbar {...props}>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text">
          <ArrowBack />
        </Button>
      </TopToolbar>
    );
  };
  const PostEditToolbar = (props) => {
    return (
      <Toolbar {...props}>
        <SaveButton />
      </Toolbar>
    );
  };
  return (
    <Edit title={'编辑' + useTxtData.page.tableName} undoable={false} {...props} actions={<PostEditActions />}>
      <MyForm Edit={true} toolbar={<PostEditToolbar />} />
    </Edit>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

//创建页面
export const StoreCreate = (props) => {
  const PostCreateActions = (props) => {
    const { basePath } = props; const redirect = useRedirect();
    return (
      <TopToolbar {...props}>
        <Button label="返回列表" onClick={() => redirect(basePath)} variant="text" >
          <ArrowBack />
        </Button>
      </TopToolbar>
    );
  };
  const PostCreateToolbar = (props) => {
    const { basePath } = props;
    return (
      <Toolbar {...props}>
        <SaveButton label="保存&列表" redirect={basePath} submitOnEnter={true} />
        <SaveButton label="保存&继续" redirect={false} submitOnEnter={false} variant="text" />
      </Toolbar>
    );
  };

  return (
    <Create title={'新增' + useTxtData.page.tableName} undoable={false} {...props} actions={<PostCreateActions />}>
      <MyForm Create={true} toolbar={<PostCreateToolbar />} />
    </Create>
  );
};

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
//Create和Edit公用表单
const MyForm = (props) => {
  let { Edit, Create } = props;
  return (
    <SimpleForm {...props} >
      {/* <TextInput source="Name" /> */}
      {/* <NumberInput source="OrderNum" /> */}
      {/* <NumberInput label={useTxtData.table.id} source="id" step={1} validate={[required()]} /> */}
      <BooleanInput label={useTxtData.table.Lock} source="Lock" />
      <TextInput label={useTxtData.table.StoreID} source="StoreID" validate={[required()]} />
      <TextInput label={useTxtData.table.ShortName} source="ShortName" validate={[required()]} />
      <TextInput label={useTxtData.table.FullName} source="FullName" validate={[required()]} />
      <UpLoadFile label={useTxtData.table.Logo} source="Logo" accept="image/*" validate={[required()]} />
      <TextInput label={useTxtData.table.Contacts} source="Contacts" validate={[required()]} />
      <TextInput label={useTxtData.table.ContactsMobile} source="ContactsMobile" validate={[required()]} />
      <TextInput label={useTxtData.table.Province} source="Province" validate={[required()]} />
      <TextInput label={useTxtData.table.City} source="City" validate={[required()]} />
      <TextInput label={useTxtData.table.Area} source="Area" validate={[required()]} />
      <TextInput label={useTxtData.table.Address} source="Address" validate={[required()]} />
      <TextInput label={useTxtData.table.Coordinate} source="Coordinate" validate={[required()]} helperText={''} />
      <Box ml={2} color="text.secondary">
        <Typography variant="caption" display="block" gutterBottom>
          获取坐标网址 <a href="https://lbs.qq.com/getPoint/" target="_blank">腾讯坐标拾取</a>
        </Typography>
      </Box>
    </SimpleForm>
  );
}