import * as React from 'react';
import { Admin, Resource, ListGuesser, EditGuesser, ShowGuesser } from 'react-admin';
import { Route } from 'react-router-dom';
import jsonServerProvider from 'ra-data-json-server';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import chinese from 'ra-language-chinese';
import dataProvider from './dataProvider';
import authProvider from './authProvider';
import MyLayout from './customLayout/layout';
import LoginPage from './loginPage';
import Dashboard from './dashboardPage';

import CropDinIcon from '@material-ui/icons/CropDin';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PeopleIcon from '@material-ui/icons/People';
import BusinessIcon from '@material-ui/icons/Business';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ReceiptIcon from '@material-ui/icons/Receipt';
import TuneIcon from '@material-ui/icons/Tune';

import ChangePwd from './customLayout/ChangePwd';
import { AdminList, AdminCreate, AdminEdit } from './components/Admin';
import { MemberList, MemberCreate, MemberEdit, MemberShow } from './components/Member';

import { StoreList, StoreCreate, StoreEdit, StoreShow } from './components/Store';
import { StoreWeiXinList, StoreWeiXinCreate, StoreWeiXinEdit, StoreWeiXinShow } from './components/StoreWeiXin';
import { StoreProductList, StoreProductCreate, StoreProductEdit, StoreProductShow } from './components/StoreProduct';

import { SetList, SetCreate, SetEdit, SetShow } from './components/Set';

import { MemberOrderList, MemberOrderCreate, MemberOrderEdit, MemberOrderShow } from './components/MemberOrder';
import { MemberOrderCommissionList, MemberOrderCommissionCreate, MemberOrderCommissionEdit, MemberOrderCommissionShow } from './components/MemberOrderCommission';

//const dataProvider = jsonServerProvider('http://localhost:5100');
//const dataProvider = jsonServerProvider('https://jsonplaceholder.typicode.com');

//补充缺失的翻译内容 一下是增加一个confirm的中文翻译
chinese.ra.action = { ...chinese.ra.action, confirm: '确认' };
//设置语言为 中文
const i18nProvider = polyglotI18nProvider(() => chinese, 'zh');


const customRoutes = [
  <Route exact path="/ChangePwd" component={ChangePwd} />,
];

const App = () => (
  <Admin
    title="S2C后台管理"
    layout={MyLayout}
    customRoutes={customRoutes}
    i18nProvider={i18nProvider}
    dataProvider={dataProvider}
    authProvider={authProvider}
    loginPage={LoginPage}
    dashboard={Dashboard}
  >
    <Resource options={{ group: '设置', label: '管理员' }} name="Admin/CMSAdmin" list={AdminList} edit={AdminEdit} icon={<AssignmentIndIcon />} />
    <Resource options={{ group: '设置', label: '平台参数' }} name="Admin/CMSSet" list={SetList} edit={SetEdit} show={SetShow} icon={<TuneIcon />} />
    <Resource options={{ group: '会员', label: '会员' }} name="Admin/CMSMember" list={MemberList} show={MemberShow} icon={<PeopleIcon />} />

    <Resource options={{ group: '商家', label: '商家信息' }} name="Admin/CMSStore" list={StoreList} create={StoreCreate} edit={StoreEdit} show={StoreShow} icon={<BusinessIcon />} />
    <Resource options={{ group: '商家', label: '设置收款' }} name="Admin/CMSStoreWeiXin" list={StoreWeiXinList} create={StoreWeiXinCreate} edit={StoreWeiXinEdit} show={StoreWeiXinShow} icon={<MonetizationOnIcon />} />
    <Resource options={{ group: '商家', label: '管理活动' }} name="Admin/CMSStoreProduct" list={StoreProductList} create={StoreProductCreate} edit={StoreProductEdit} show={StoreProductShow} icon={<ReceiptIcon />} />

    <Resource options={{ group: '交易', label: '交易记录' }} name="Admin/CMSMemberOrder" list={MemberOrderList} show={MemberOrderShow} icon={<ReceiptIcon />} />
    <Resource name="Admin/CMSMemberOrderCommission" />

  </Admin>
);

export default App;